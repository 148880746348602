export const TestActionTypes = {
  LOGIN_TEST_REQUEST: "@@test/LOGIN_TEST_REQUEST",
  LOGIN_TEST_SUCCESS: "@@test/LOGIN_TEST_SUCCESS",
  LOGIN_TEST_FAILURE: "@@test/LOGIN_TEST_FAILURE",
};

export const testInitialState = {
  socials: [],
  isLoading: false,
};

export const testSelector = (state) => state.TestReducer;
