import { TestActionTypes, testInitialState } from "./test.model";

const reducer = (state = testInitialState, action) => {
  switch (action.type) {
    case TestActionTypes.LOGIN_TEST_REQUEST:
    case TestActionTypes.LOGIN_TEST_FAILURE:
      return {
        ...state,
        isLoading: true,
      };
    case TestActionTypes.LOGIN_TEST_SUCCESS:
      console.log(action, "akdfgdakj");
      const { payload } = action;
      return {
        ...state,
        isLoading: false,
        socials: payload?.data,
      };

    default:
      return state;
  }
};

export { reducer as TestReducer };
